<template>
	<div class="cover">
		<div class="a4 ql-editor texta4" ref="print" style="padding: 50px 100px;position: relative;">
			<div style="width: 100%;display: flex;align-items: center;justify-content: center;">
				<img src="../../../assets/sqmy.png" alt="" style="width: 50%;height: auto;margin: auto;"/>
			</div>
			
			<div style="font-size:30px;text-align: center;font-family: fangsong;margin-top: 20px;">
				第&nbsp;xxxxx&nbsp;期</div>
			<div
				style="text-align: center;font-family: fangsong;display: flex;justify-content: space-between;margin-top: 40px;">
				<span class="zwfontsize">政协西宁市委员会办公室编</span>
				<span class="zwfontsize">{{formData.createtime}}</span>
			</div>
			<div style="width: 100%;white-space: nowrap;overflow: hidden;margin-top: 0px;color: #E23D00;">
				═════════════════════════════════════════════════════════════════════════════════════════════════════
			</div>			
			<div class="zwfontsize" style="font-family: fangsong;margin-top: 30px;display: flex;align-items: center;justify-content: center;">
				<div style="width: 80%;color: #333333;font-weight: bold;font-size: 28px;text-align: center;">
					{{formData.name}}
				</div>				
			</div>
			<div style="margin-top: 40px;font-weight: bold;font-family: fangsong;">
				问题分析：
			</div>
			<div style="margin-top: 20px;font-family: fangsong;line-height: 35px;">
				&nbsp;&nbsp;&nbsp;&nbsp;
				<span style="font-weight: bold;">{{formData.user_name}}反映：</span>{{formData.problem_analysis}}
			</div>
			<div style="margin-top: 40px;font-weight: bold;font-family: fangsong;">
				建议办法：
			</div>
			<div style="margin-top: 20px;font-family: fangsong;line-height: 35px;">
				&nbsp;&nbsp;&nbsp;&nbsp;{{formData.suggested_approach}}
			</div>	
		</div>
		<div style="display: flex;flex-direction: column;margin-left: 30px;">
			<slot name="otherbtn">
			    
			</slot>
			<el-button v-if="showdy" type="success" size="small" @click.stop="print" style="margin-bottom:10px;margin-left: 10px;">打印</el-button>
			</el-button>
			<el-button type="primary" size="small" @click.stop="closeyl">关闭</el-button>
			</el-button>			
		</div>
	</div>
</template>

<script>
	import { exportWord, getBase64Sync, getBase } from "@/utils/getword.js";

	export default{
		// props:['id','requsturl',"type",'ys_id',"showbohui"],
		props:{
			id:{
				type:String,
				default:""
			},
			requsturl:{
				type:String,
				default:""
			},
			type:{
				type:String,
				default:""
			},
			ys_id:{
				type:String,
				default:""
			},
			ys_id:{
				type:String,
				default:""
			},
			showbohui:{
				type:Boolean,
				default:false
			},
			showdy:{
				type:Boolean,
				default:true
			}
		},
		data(){
			return{
				imaglist:[],
				fileList:[],
				formData:'',
				yjdw:'',
				yj:''
			}
		},
		created() {
			this.$post({
				url: '/api/sqmy/details',
				params: {
					id:this.id
				}
			}).then((res) => {
				this.formData = res
				
			})
		},
		methods:{
			async exportWordTest() {	
			   let url = 'http://czxapi.zhihuiqinghai.com/upload/20230309/47710ab1db3e59b204481dcd2f08add1.jpg'
			 //具体数据根据实际情况灵活变动
			 let users =[]
			 let users_idsarray =[]
			   // let users = this.formData.joint_proposal_users.map((item)=>{
				  //  item.yb = '810003'
				  //  return item
			   // })
			   // let users_idsarray = this.formData.joint_proposal_user_ids_array.map((item)=>{
			   // 		item.yb = '810003'
			   // 		return item
			   // })
				let data = {					
					huiyi: this.formData.id,
					code: this.formData.id,
					classify_name:this.formData.lei_name,
					plan_1_time:this.formData.addtime,
					title:this.formData.name,
					idea:this.formData.idea?this.formData.idea:'',
					issue:this.formData.content,
					proposal:this.formData.way,
					joint_proposal_users:this.formData.user_name,
					joint_proposal_user_ids_array:this.formData.lmusers_name
					// image: await getBase64Sync(url),
				}
			 
			 	exportWord("../static/test.docx", data, this.formData.name+".docx");
			},
			getword(){
				this.exportWordTest()
				// this.$axios
				//   .get('http://czxapi.zhihuiqinghai.com/upload/20230309/47710ab1db3e59b204481dcd2f08add1.jpg')
				//   .then((res) => {
					
				//   })
				//   .catch((err) => err);
			},
			open(url){
				window.open(url)
			},
			print(){
				this.$printPage(this.$refs.print)
			},
			closeyl(){
				this.$emit("closedetail")
			}
		}
	}
</script>

<style lang="scss" scoped>
	.cover {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		width: 100vw;
		height: 100vh;
		overflow-y: auto;
		background-color: rgba(0, 0, 0, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		.a4 {
			height: 100%;
			width: 210mm;
			background-color: #fff;
	
			::v-deep h1 {
				font-size: 30px !important;
			}
		}
	
		.texta4 {
			min-height: 95%;
		}
	}
	
	.zwfontsize {
		font-size: 22px;
	}
	
	.mytable {
		width: 100%;
		margin-top: 50px;
	
		.table_title span {
			text-align: center;
			font-weight: 400;
			font-size: 22px;
			font-family: fangsong;
			width: 20%;
		}
	
		.table_title span:nth-child(2) {
			width: 40%;
		}
	
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: center;
	
			span {
				border: 1px solid #000000;
			}
	
			span:nth-child(1),
			span:nth-child(2),
			span:nth-child(3) {
				border-right: none;
				border-bottom: none;
			}
	
			span:nth-child(4) {
				border-bottom: none;
			}
		}
	
		div:nth-last-child(1) {
	
			span:nth-child(1),
			span:nth-child(2),
			span:nth-child(3) {
				border-right: none;
				border-bottom: 1px solid #000000;
			}
	
			span:nth-child(4) {
				border-bottom: 1px solid #000000;
			}
		}
	
		.table_cont span {
			text-align: center;
			font-family: fangsong;
			font-weight: 400;
			font-size: 18px;
			width: 20%;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	
		.table_cont span:nth-child(2) {
			width: 40%;
		}
	}
	
	::v-deep h2 span {
		font-size: 26px;
	}
	
	::v-deep h1 strong {
		font-size: 32px;
	}
	
	.yjzx_table {
		margin-top: 30px;
		font-family: fangsong;
		font-size: 18px;
		width: 100%;
		border: 1px solid #000;
		border-collapse: collapse;
	
		td {
			border: 1px solid #000;
			height: 45px;
			padding: 4px 5px;
			text-align: center;
		}
	}
	
	.yuanitem {
		position: relative;
	}
	
	.dagou:before {
		position: absolute;
		content: '';
		height: 15px;
		width: 27px;
		border-left: 2px solid #000;
		border-bottom: 2px solid #000;
		transform: rotate(-45deg);
	}
	
	
	
	::v-deep .el-form-item{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	::v-deep .el-form-item__label{
		width: fit-content !important;
	}
	::v-deep .el-form-item__content{
		margin-left: 0px !important;
	}
	::v-deep .el-input{
		width: 9rem;
	}
	::v-deep .el-input > .el-input__inner{
		min-width: auto;		
	}
	::v-deep .mysearchinput .el-input__inner{
		min-width: auto;
	}
	.mysearchtitile{
		font-size: 14px;
		color: #606266;
		font-weight: 700;
	}
	::v-deep  .el-date-editor.el-input__inner{
		width: 415px;
	}
</style>